<template>
	<main class="page-container container">
		<div class="section__top mb-20">
			<div class="section__top-left">
				<AppText
					:size="isMobileSmall ? 18 : isMobile ? 22 : 26"
					weight="600"
					:line-height="isMobileSmall ? 22 : isMobile ? 28 : 34"
					class="font_Hsemibold"
				>№{{ data.selectionNumber }} - Продукты питания
				</AppText>
			</div>
			<selection-time-counter :key="timeCounterKey" :time="data.endDate">
				<div class="mr-10">
					<app-button v-if="canSendRequest" class="mr-30">
						<a :href="baseUrl" class="a-btn-a a-btn-a--th-accent go-to-btn">Подать предложение</a>
					</app-button>

					<button v-else class="a-btn-a a-btn-a--th-accent go-to-btn" disabled>Подать предложение</button>
				</div>
			</selection-time-counter>
		</div>

		<div class="section__body" :class="isMobileSmall ? 'mb-30' : 'mb-60'">
			<product-details-table v-if="data.productLists" :data="data.productLists"></product-details-table>
			<download-files :files="files" title="Файлы для загрузки:"></download-files>
			<div>
				<app-content-card
					class="custom-tab-component"
					padding-x="0"
					padding-y="0"
					:radius="[8, 8, 0, 0]"
				>
					<app-tabs v-model="selectedTab" :list="tabItems" class="font_Hsemibold"/>
				</app-content-card>
				<customer-data-tab v-if="selectedTab === 'directContracts'" :data="data"></customer-data-tab>
				<general-info-tab v-if="selectedTab === 'auction'" :data="data"></general-info-tab>
				<criteria-tab v-if="selectedTab === 'criteria'" :data="data.criteriaLists"></criteria-tab>
				<contract-tab v-if="selectedTab === 'contract'" :data="data"></contract-tab>
			</div>
		</div>
	</main>
</template>

<script>
import AppText from "@/components/shared-components/AppText";
import AppButtonA from "@/components/shared-components/AppButtonA";
import AppContentCard from "@/components/shared-components/AppContentCard";
import AppTabs from "@/components/shared-components/AppTabs";
import AppButton from "@/components/shared-components/AppButton";

import SelectionTimeCounter from "@/components/pages/selection/SelectionTimeCounter";
import ProductDetailsTable from "@/components/pages/selection/ProductDetailsTable";
import CustomerDataTab from "@/components/pages/selection/CustomerDataTab";
import GeneralInfoTab from "@/components/pages/selection/GeneralInfoTab";
import CriteriaTab from "@/components/pages/selection/CriteriaTab";
import ContractTab from "@/components/pages/selection/ContractTab";
import DownloadFiles from "@/components/pages/common/DownloadFiles";

import SelectionService from "@/services/api/selection-service";
import {tenderTypes} from "@/static/statusIds";

export default {
	name: "SelectionItemDetails",
	components: {
		AppText,
		AppButtonA,
		AppContentCard,
		AppTabs,
		AppButton,
		SelectionTimeCounter,
		ProductDetailsTable,
		CustomerDataTab,
		GeneralInfoTab,
		CriteriaTab,
		ContractTab,
		DownloadFiles,
	},

	data() {
		return {
			timeCounterKey: 1,
			tenderType: null,
			tenderTypes,
			selectedTab: "directContracts",
			tabItems: [
				{
					name: this.$i18n.t("ИНФОРМАЦИЯ О ЗАКАЗЧИКЕ"),
					value: "directContracts",
				},
				{
					name: this.$i18n.t("ОБЩИЕ ДАННЫЕ"),
					value: "auction",
				},
				{
					name: this.$i18n.t("КРИТЕРИИ"),
					value: "criteria",
				},
				{
					name: this.$i18n.t("ДОГОВОР"),
					value: "contract",
				},
			],
			id: null,
			data: {},
			adminUrl: process.env.VUE_APP_ADMIN_URL,
		};
	},
	created() {
		this.tenderType = this.$store.getters.getTenderType();
		this.getId();
		this.getSelection();
	},
	methods: {
		getId() {
			const id = this.$route.params?.id;
			if (id) {
				this.id = +id;
			}
		},
		getSelection() {
			if (!this.id) {
				this.$notify({type: "error", text: this.$t("no_id")});
				return;
			}

			SelectionService.getSelectionItem(this.id).then(
				(response) => {
					this.data = response.data.result;
					this.timeCounterKey = this.timeCounterKey + 1;
					if (this.isValidTime) {
						setTimeout(() => {
							this.data = {...this.data};
						}, this.differenceTime);
					}
				},
				(error) => console.log(error)
			);
		},
	},
	computed: {
		canSendRequest() {
			let data = this.data;
			let userInfo = this.$store.state.userInfo;
			let iAmCreator = data.companyId === userInfo.id;
			return !data.total && this.isValidTime && !iAmCreator;
		},
		isValidTime() {
			return this.differenceTime > 0;
		},
		differenceTime() {
			let data = this.data;
			let time = data.endDate;
			const productDate = time ? Date.parse(time) : 0;
			const todayDate = Date.parse(new Date());
			return productDate - todayDate
		},
		files() {
			return this.data.files + ";" + this.data.attorneyFiles;
		},
		baseUrl() {
			const urlName =
				this.tenderType === this.tenderTypes.SELECTION ? "selection" : "tender";
			return (
				this.adminUrl +
				`/#/cabinet/${urlName}/details/business/` +
				this.id
			);
		},
	},
};
</script>

<style lang="scss" scoped>
.secondary-table {
	text-align: center;
	background: #fafdff;

	th {
		background: var(--color-secondary);
		color: white;
		text-align: center;
		padding: 22px;
		border-right: 1px solid #684687;
	}
}

.secondary-table th {
	padding: 15px;
}

.go-to-btn {
	// padding: 30px 0;
	border-radius: 8px;
	font-size: 14px;
	height: 40px;
	line-height: 18px;
	display: flex;
	align-items: center;
	padding: 15px 20px;
	font-weight: 600;
}
</style>
