import { render, staticRenderFns } from "./SelectionItemDetails.vue?vue&type=template&id=211c9f85&scoped=true&"
import script from "./SelectionItemDetails.vue?vue&type=script&lang=js&"
export * from "./SelectionItemDetails.vue?vue&type=script&lang=js&"
import style0 from "./SelectionItemDetails.vue?vue&type=style&index=0&id=211c9f85&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "211c9f85",
  null
  
)

export default component.exports